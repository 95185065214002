<template>
  <b-container fluid="lg">
    <div v-for="b in blogs" :key="b.id">
    <h1>{{b.title}}</h1>
    <hr>
    <vue-markdown :source="b.text" />
    </div>
  </b-container>
</template>
<script>
// @ is an alias to /src
import siteMeta from '../site'
import VueMarkdown from 'vue-markdown-render'

export default {
  components: {VueMarkdown},
  data: () => {
    return {
      blogs:[]
    }
  },

  methods: {
    fetchMore() {
      fetch(
        siteMeta.cmsUrl +
          `blogs/?title=${this.$route.params.title}`,
      )
        .then((res) => {
          return res.json()
        })
        .then(data=>{
          this.blogs=data
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {

    this.fetchMore()
  },
}
</script>

<style scoped></style>